import React from 'react';

//style import
import '../../style/background.css';

function Background({view, view2, view3, view4, view5, view6, view7, view8, view9, view10, view11, view12, filter}) {
   // style prop declaration
   const backgroundStyle = {
    backgroundImage: [
      `url(${view})`,
      `url(${view2})`,
      `url(${view3})`,
      `url(${view4})`,
      `url(${view5})`,
      `url(${view6})`,
      `url(${view7})`,
      `url(${view8})`,
      `url(${view9})`,
      `url(${view10})`,
      `url(${view11})`,
      `url(${view12})`,
    ],
    filter: filter
   }
  return (
    <div>
        <>
         <div id='backgroundResponsive' className='background slide' style={backgroundStyle}>
         </div>
         <style>{`
                     .slide {
                       animation: slide 50s infinite;
                     }
                     @keyframes slide {
                       0% {
                         background-image: url(${view});
                       }
                       6.67% {
                         background-image: url(${view2});
                       }
                       13.34% {
                         background-image: url(${view3});
                       }
                       20.01% {
                         background-image: url(${view4});
                       }
                       26.68% {
                         background-image: url(${view5});
                       }
                       33.35% {
                         background-image: url(${view6});
                       }
                       40.02% {
                         background-image: url(${view7});
                       }
                       46.69% {
                         background-image: url(${view8});
                       }
                       60.03% {
                         background-image: url(${view9});
                       }
                       66.7% {
                         background-image: url(${view10});
                       }
                       73.37% {
                         background-image: url(${view11});
                       }
                       80.04% {
                         background-image: url(${view12});
                       }
     `}
     </style>
       </>
    </div>
  )
}

export default Background;