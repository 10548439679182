// features import
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// component import
import Loading from './loading';

const url = 'https://guess-am-past-events-default-rtdb.europe-west1.firebasedatabase.app/.json'

export function AddPastEvent() {
// component hooks
  const [isLoading, setIsloading] = useState(true);
  const [isFading, setIsfading] = useState(true);
  const [showForm, setShowform] = useState(false);
  const [showText, setShowtext] = useState('Show form')
  const navigate = useNavigate();
  const [ pastEvent, setPastevent ] = useState({
        titolo:'',
        data:'',
        luogo:'',
        descrizioneLuogo:'',
        oraInizio:'',
        oraFine: ''
  });

  // methods for changing component state
  useEffect(()=>{
    setTimeout(()=>{
      setIsloading(false)
    },1000)
  });

  useEffect(()=>{
    setTimeout(()=>{
      setIsfading(false)
    }, 1500)
  })

  const handleShowform = () => {
    setShowform(!showForm)
    if(!showForm) {
      setShowtext('Hide form')
    }else{
      setShowtext('Show Another time :)')
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setPastevent({...pastEvent, [name]:value})
  };

  const handleSubmit = async() => {
    const succesMessage = 'data post success';
    navigate('/');
    try {
        await axios.post(url, pastEvent)
          alert(succesMessage)
      } 
    catch (error) {
          alert(error)
      }
  };

  // motion div styling
     const initial = {
      opacity: 0, 
      maxHeight: 0
    };

    const animate = {
      opacity: 1,
      maxHeight: '100%' 
    };

    const exit = {
      opacity: 0, 
      maxHeight: 0 
    };
    
  return (
    <>
      {
          isLoading 
              ? 
          <Loading/>
              : 
        <>
          <div style={{textAlign:'center'}}className = {` loading-container ${isFading ? "fade-out" : "fade-in"}`}>
            <button id='showFormButton' onClick={handleShowform} className='btn btn-success mt-3'>{showText}</button>
            <button id='navigateButton' onClick={()=>navigate('/')} className='btn btn-success mt-3 mx-4'>Home page</button>
          </div>
        </>
      }
      <AnimatePresence>
        {showForm && (
          <motion.form 
            className={`p-3 my-5 mx-5 rounded`} 
            onSubmit={handleSubmit}
            initial={initial}
            animate={animate}
            exit={exit}
            >
            <div className='form-row'>
              <div className="form-group ">
                <label htmlFor='titolo' ><b>Titolo:</b></label>
                <input required  onChange={handleChange} style={{textAlign:'center'}} name='titolo' id='titolo' type="text" className="form-control" placeholder="Titolo"/>
              </div>
              <div className="form-group ">
                <label htmlFor='data'><b>Data:</b></label> 
                <input required onChange={handleChange} style={{textAlign:'center'}} name='data' id='data' type="date" className="form-control" placeholder="gg/mm/aa"/>
              </div>
              <div className="form-group ">
                <label htmlFor='luogo'><b>Luogo:</b></label> 
                <input required onChange={handleChange} style={{textAlign:'center'}} name='luogo' id='luogo' type="text" className="form-control" placeholder="Link posizione esatta"/>
              </div>
              <div className="form-group ">
                 <label htmlFor='descrizioneLuogo'><b>Descrizione Luogo:</b></label> 
                <input required onChange={handleChange} style={{textAlign:'center'}} name='descrizioneLuogo' id='descrizioneLuogo' type="text" className="form-control" placeholder="Via/Piazza Comune Provincia"/>
              </div>
              <div className="form-group ">
                 <label htmlFor='oraInizio'><b>Ora Inizio:</b></label> 
                <input required onChange={handleChange} style={{textAlign:'center'}} name='oraInizio' id='oraInizio' type="text" className="form-control" placeholder="hh:mm"/>
              </div>
              <div className="form-group ">
                 <label htmlFor='oraFine'><b>Ora Fine:</b></label> 
                <input required onChange={handleChange} style={{textAlign:'center'}} name='oraFine' id='oraFine' type="text" className="form-control" placeholder="hh:mm"/>
              </div>
            </div>
            <button style={{fontWeight:'bold', fontFamily:'Celticmd'}} className='btn btn-info mt-3' type='submit' >Submit</button>
          </motion.form>
        )}
      </AnimatePresence>
    </>
  )
};
