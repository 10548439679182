// features import
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// style import
import '../../style/pastevent.css';
import 'animate.css/animate.min.css';

function Pastevent({titolo, data, oraInizio, oraFine, luogo, descrizioneLuogo, immagine}) {
// component hooks
  const [isHovered, setIshovered] = useState(true);
  const [show, setShow] = useState(false);
  const [buttonTabText, setButtonTabText] = useState('Click here');

// fuctions for changin component state
  const handleMouseEnter = () => {
    setIshovered(false)
  };

  const handleMouseLeave = () => {
    setIshovered(true)
  };

  const handleShow = () => {
    setShow(!show)
    !show ? setButtonTabText('Go back') : setButtonTabText('Click here')
  };

  const handleComingSoon = () => {
    alert('be patient, photos are up to be loaded')
  }

  const linkStyle = {
    color: isHovered ? 'white' : 'green',
    transition: 'color 0.5s ease'
  };

  if ( immagine === undefined ) {
    return (
      <table id='pasteventTabId'className="table container border border-success animate__animated animate__slideInLeft ">
        <tbody>
          <tr>
            <td colSpan='2'>{titolo}</td>
          </tr>
          <tr>
              <th scope="row">Data</th>
              <td colSpan="2">{data}</td>
          </tr>
          <tr>
              <th scope="row">Dalle</th>
              <td colSpan="2">{oraInizio}</td>
          </tr>
          <tr>
              <th scope="row">Alle</th>
              <td colSpan="2">{oraFine}</td>
          </tr>
          <tr>
              <th onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={linkStyle} scope="row">Luogo</th>
              <td onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} colSpan="2"><a style={linkStyle} href={luogo} target={'target'} rel={'rel'}>{descrizioneLuogo}</a></td>
          </tr>
          <tr>
            <th scope="row">Galleria</th>
            <td>
              <b onClick={handleComingSoon}>
                <u className='text-warning'>Coming soon</u>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    )
  };

  return (
    <>
       <table id='pasteventTabId'className="table container border border-success animate__animated animate__slideInLeft ">
              <tbody>
                <tr>
                  <td colSpan='2'>{titolo}</td>
                </tr>
                <tr>
                    <th scope="row">Data</th>
                    <td colSpan="2">{data}</td>
                </tr>
                <tr>
                    <th scope="row">Dalle</th>
                    <td colSpan="2">{oraInizio}</td>
                </tr>
                <tr>
                    <th scope="row">Alle</th>
                    <td colSpan="2">{oraFine}</td>
                </tr>
                <tr>
                    <th onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={linkStyle} scope="row">Luogo</th>
                    <td onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} colSpan="2"><a style={linkStyle} href={luogo} target={'target'} rel={'rel'}>{descrizioneLuogo}</a></td>
                </tr>
                <tr>
                    <th scope="row">Galleria</th>
                    <td onClick={handleShow} colSpan="2"><button className='btn btn-success'>{buttonTabText}</button></td>
                </tr>
              </tbody>
        </table>
        <AnimatePresence>
          {show && (
            <>
              <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{duration:1}}} exit={{opacity:0, transition:{duration:1}}} className='bg-dark rounded border border-success border-2 m-3 p-3'>
                {immagine.photo1 && immagine.photo2 &&(
                  <>
                    <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{duration:1}}} exit={{opacity:0, transition:{duration:1}}} id='imgContainerId' className='images container'>
                      <img className='firstImage' src={immagine.photo1} alt="..."/>
                      <img className='secondImage' src={immagine.photo2} alt="..."/>
                    </motion.div>
                  </>
                )}
                {immagine.photo3 && immagine.photo4 && (
                  <>
                    <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{duration:1}}} exit={{opacity:0, transition:{duration:1}}} id='imgContainer2Id' className='images container'>
                      <img className='firstImage' src={immagine.photo3} alt="..." />
                      <img className='secondImage' src={immagine.photo4} alt="..." />
                    </motion.div>
                  </>
                )}
                </motion.div>
            </>
          )}
        </AnimatePresence>
    </>
  )
};


export default Pastevent;
