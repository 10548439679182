import { useState, useEffect } from "react";
import axios from "axios";

// url api
const urlEvents = 'https://guess-am-events-76cf7-default-rtdb.europe-west1.firebasedatabase.app/.json';
const urlPastEvents = 'https://guess-am-past-events-default-rtdb.europe-west1.firebasedatabase.app/.json';
const urlPresentation = 'https://presentation-69c4d-default-rtdb.europe-west1.firebasedatabase.app/.json';
const urlCards = 'https://guess-am-cards-77ce5-default-rtdb.europe-west1.firebasedatabase.app/.json';

// data fetching
export const useFetchEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(urlEvents);
        const events = Object.values(response.data)
        // Ordino la reindirizzazione degli eventi in base alla data di questi ultimi
        const sortedEvents = [...events].sort((a,b)=> new Date(a.data) - new Date(b.data));
        setEvents(sortedEvents)
      } catch (error) {
        console.log(error)
      }
    })();
  }, []);
  return (
    events
  )
};

export const useFetchPastEvents = () => {
  const [pastEvents, setPastEvents] = useState([]);
  useEffect(()=>{
    (async () => {
      try {
        const response =  await axios.get(urlPastEvents)
        const pastEvents = Object.values(response.data)
        // Ordino la reindirizzazione degli eventi conclusi in base alla data di questi ultimi
        const sortedPastEvents = [...pastEvents].sort((a,b)=> new Date(b.data) - new Date(a.data));
        setPastEvents(sortedPastEvents)
      } catch (error) {
        console.log(error)
        alert(error)
      }
    })()
  },[])
  return (
    pastEvents
  )
};

export const useFetchPresentation = () => {
  const [presentation, setPresentation] = useState([]);
  useEffect(()=>{
    (async () => {
        try {
          const response = await axios.get(urlPresentation)
          const presentationValue = Object.values(response.data);
          setPresentation(presentationValue)
          // console.log(presentationValue)
        } catch (error) {
          console.log(error)
          alert(error)
        }
      }
    )()
  },[])
  return (
    presentation
  )
};

export const useFetchCards = () => {
  const [cards, setCards] = useState([]);
  useEffect(()=>{
    ( async() => {
      try {
        const response = await axios.get(urlCards);
        const cardsValue = Object.values(response.data)
        setCards(cardsValue)
      } catch (error) {
        console.log(error)
      }
     }
    )()
  },[])
  return (
    cards
  )
};