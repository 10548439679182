// features import
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// component import
import Loading from './loading';

// stylesheet import
import '../../style/addevent.css';

// url and params api
const url = 'https://guess-am-events-76cf7-default-rtdb.europe-west1.firebasedatabase.app/.json/';
const authParam = '?auth='



export function Addevent() {
// component hooks
    const [isLoading, setIsloading] = useState(true);
    const [isFading, setIsfading] = useState(true);
    const [showForm, setShowform] = useState(false);
    const [showformText, setShowformtext] = useState('Show Form');
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [event, setEvent] = useState({
        titolo:'',
        data:'',
        luogo:'',
        descrizioneLuogo:'',
        oraInizio:'',
        oraFine: ''
    });
    const [token, setToken]= useState('');

    useEffect(() => {
      setToken(localStorage.getItem('token'))
      setTimeout(() => {
        setIsloading(false)
      }, 1000)
    }, [token]);

    useEffect(() => {
      setTimeout(() => {
        setIsfading(false)
      }, 1500)
    });

    // methods for changing component state
    const handleShowform = () => {
      setShowform(!showForm)
      if(!showForm) {
        setShowformtext('Hide Form')
      }else {
        setShowformtext('Show Form')
      }
    };

   
    const validateForm = () => {
      const {  oraInizio, oraFine } = event;

      // validazione oraInizio
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if(!timeRegex.test(oraInizio)) {
        errors.oraInizio = 'Bisogna inserire un l\'ora di inizio attività valida'
        alert(errors.oraInizio)
        
      // validazione oraFine
      }else if(!timeRegex.test(oraFine)) {
        errors.oraFine =  'Bisogna inserire un l\'ora di fine attività valida'
        alert(errors.oraFine)
      }else if (oraInizio > oraFine ) {
        errors.oraInizio = 'L\'ora in cui si comincia dev\'essere precedente all\'ora in cui termina l\'evento, genio!'
        alert(errors.oraInizio)
      }
     setErrors(errors);
     return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const {name, value} = e.target
        setEvent({...event, [name]:value})
    };

    const handleSubmit = async() => {
        navigate('/');
        try {
          if (validateForm()) {
            await axios.post(url+authParam+token, event)
            alert('data post success')
          }
        } catch (error) {
          alert('you do not have permissions, please login')
          navigate('/login')
        };
        // e.preventDefault()
    };
    // motion div styling
    const initial = {
      opacity: 0, 
      maxHeight: 0
    };

    const animate = {
      opacity: 1,
      maxHeight: '100%' 
    };

    const exit = {
      opacity: 0, 
      maxHeight: 0 
    };
    
  return (
    <>
     {
        isLoading 
            ? 
        <Loading/> 
            : 
        <div style={{textAlign:'center'}} className = {` loading-container ${isFading ? "fade-out" : "fade-in"}`}>
            <button id='showFormButton' className='btn btn-success mt-3' onClick={handleShowform}>{showformText}</button>
            <button id='navigateButton' className='btn btn-success mt-3 mx-4' onClick={() => navigate('/')}>Homepage</button>
        </div>
      }
      <AnimatePresence>
        {showForm && (
          <motion.form 
            className={`p-3 my-5 mx-5 rounded`}
            onSubmit={handleSubmit}
            initial={initial}
            animate={animate}
            exit={exit}
           >
            <div className="form-row">
                <div className="form-group ">
                    <label htmlFor='titolo' ><b>Titolo:</b></label>
                  <input required  onChange={handleChange} style={{textAlign:'center'}} name='titolo' id='titolo' type="text" className="form-control" placeholder="Titolo"/>
                </div>
                <div className="form-group ">
                   <label htmlFor='data'><b>Data:</b></label> 
                  <input required onChange={handleChange} style={{textAlign:'center'}} name='data' id='data' type="date" className="form-control" placeholder="gg/mm/aa"/>
                </div>
                <div className="form-group ">
                   <label htmlFor='luogo'><b>Luogo:</b></label> 
                  <input required onChange={handleChange} style={{textAlign:'center'}} name='luogo' id='luogo' type="text" className="form-control" placeholder="Link posizione esatta"/>
                </div>
                <div className="form-group ">
                   <label htmlFor='descrizioneLuogo'><b>Descrizione Luogo:</b></label> 
                  <input required onChange={handleChange} style={{textAlign:'center'}} name='descrizioneLuogo' id='descrizioneLuogo' type="text" className="form-control" placeholder="Via/Piazza Comune Provincia"/>
                </div>
                <div className="form-group ">
                   <label htmlFor='oraInizio'><b>Ora Inizio:</b></label> 
                  <input required onChange={handleChange} style={{textAlign:'center'}} name='oraInizio' id='oraInizio' type="text" className="form-control" placeholder="hh:mm"/>
                </div>
                <div className="form-group ">
                   <label htmlFor='oraFine'><b>Ora Fine:</b></label> 
                  <input required onChange={handleChange} style={{textAlign:'center'}} name='oraFine' id='oraFine' type="text" className="form-control" placeholder="hh:mm"/>
                </div>
            </div>
            <button style={{fontWeight:'bold', fontFamily:'Celticmd'}} className='btn btn-info mt-3' type='submit'>Submit</button>
        </motion.form>
       )}
      </AnimatePresence>
    </>
  )
};
