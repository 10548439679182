//importa il componente e lo reindirizza alla paginaweb tramite react render

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';


// route data import
import { routes } from './data/routes';

// routing react library import
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {routes.map((route, index)=>{
          return (
           <Route
              key={index}
              path={route.path}
              element={route.element}
           />
          )
        })}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

