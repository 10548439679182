import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { firebaseConfig } from '../../../environments/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading';

function Login() {
    const apiKey = firebaseConfig.apiKey;
    const loginApi = firebaseConfig.loginApi;
    // components hooks
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [fading, setFading] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    useEffect(()=>{
        setTimeout(()=>{
            setFading(false)
        }, 1500)
    })

    const [login, setLogin] = useState(
        {
            email:'',
            password: '',
            returnSecureToken:true
        }
    )
    // setValue method
   const handleChange =(e)=>{
        const {name, value} = e.target
        setLogin({...login, [name]:value})
    }

    const handleSubmit = (e) => {
        const loginUrl = loginApi + apiKey
        axios.post(loginUrl, login)
        .then(
            res => {
                let idToken = res.data.idToken
                // console.log(res.data.idToken)
                localStorage.setItem('token',idToken)
                navigate("/")
            }
        )
        .catch(
            ()=>{
                alert('email o passord are not correct')
            }
        )
        return e.preventDefault()
    }
  return (
   <>
    {
        loading ? (
            <Loading/>
        ):(
            <div className={`loading-container ${fading ? 'fade-out':'fade-in'}`}>
                <form  className={`p-3 my-5 mx-5 rounded`} onSubmit={handleSubmit}>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor='email'>Email address</label>
                            <input style={{textAlign:'center'}} className='form-control' onChange={handleChange} id='email' type='text' placeholder='ex@mail.com' name='email'></input>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='password'>Password</label>
                            <input style={{textAlign:'center'}}className='form-control' onChange={handleChange} id='password' type='password' placeholder='password' name='password'></input>
                        </div>
                    </div>
                <button style={{marginTop:'5px', fontWeight:'bold', fontFamily:'Celticmd'}} className='btn btn-info' type='submit'>Submit</button>
                </form>
            </div>
        )
    }
        
   </>
    
  )
}

export default Login;
