import React  from 'react';

// style & features library import
import '../../style/modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Modal({nome, info, instaProfile, faceProfile}) {

  if(instaProfile === undefined) {
    return(
      <div className='Modal' id='modal'>
      <div id='modalContent' className='modal-content'>
      <h2>{nome}</h2>
        <p>{info}</p>
        <label className='contactHim'>
          contact him:
          <a href={faceProfile} target='_blank' rel='noreferrer noopener'>
           <FontAwesomeIcon id='faceIcon' className='Icon' icon={faFacebook}/>
          </a>
        </label>
      </div>
    </div>
    )
  }
  
  return (
    <>
      <div className='Modal' id='modal'>
        <div id='modalContent' className='modal-content'>
        <h2>{nome}</h2>
          <p>{info}</p>
          <label className='contactHim'>
            contact him:
            <a href={instaProfile} target='_blank' rel='noreferrer noopener'>
             <FontAwesomeIcon id='instaIcon' className='Icon' icon={faInstagram}/>
            </a>
            <a href={faceProfile} target='_blank' rel='noreferrer noopener'>
             <FontAwesomeIcon id='faceIcon' className='Icon' icon={faFacebook}/>
            </a>
          </label>
        </div>
      </div>
    </>
  )
};

export default Modal;
