import React, { useEffect, useState } from 'react';

//components import
import Loading from './screen/components/loading';
import Home from './screen/components/home'


//helmet import
import { Helmet, HelmetProvider } from "react-helmet-async";

//features import
import 'animate.css/animate.min.css';

function App() {

  const [loading, setLoading] = useState(true);
  const [fading, setFading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFading(false)
    },1500)
  }, []);

  return (
      <HelmetProvider>
        {
          loading
              ?
            <Loading/>
              :
            <div id='app' className={` loading-container ${fading ? "fade-out" : "fade-in"}`}>
              <button className="btn btn-info">pippo</button>
                <Helmet><title>Guessam - Irish Music Trio</title></Helmet>
                 <Home/>
            </div>
        }
      </HelmetProvider>
  )
}

export default App;