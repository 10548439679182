import React from 'react'

//style
import '../../style/footer.css';


function Footer({copyright}) {
  return (
    <footer className='footer-content'>
      <p>{copyright}</p>
    </footer>
  )
};

export default Footer;