import React from 'react';

// style import and features
import '../../style/contact.css';
import 'animate.css/animate.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <div>
       <div className='contacts'>
          <div id='instaLink' className="container animate__animated animate__slideInLeft">
             <span>
               <p className='instaRow bg-dark'>
                 <a className='instaLink' href='https://www.instagram.com/guess.am_official/' target='_blank' rel='noreferrer noopener'>
                   <FontAwesomeIcon icon={faInstagram} className='instaIcon' />
                 </a>
               </p>
             </span>
         </div>
         <div id='faceLink' className="container-sm animate__animated animate__slideInRight">
           <span>
             <p className='faceRow bg-dark'>
               <a className='faceLink' href='https://www.facebook.com/guess.amofficial' target='_blank' rel='noreferrer noopener'>
                 <FontAwesomeIcon icon={faFacebook} className='faceIcon'/>
               </a>
             </p>
           </span>
         </div>
       </div>
    </div>
  )
};

export default Contact;