//img import
import Logo from '../images/logo.png';
import Titolo from '../images/guessam.png';
import Allofus from '../images/groupimg.jpg';


export const navbar = { 
  logo:{
    immagine:Logo,
    titolo:Titolo,
    groupInfo: {
        title:'Guess Am – from Lombardy cùnt furóri!',
        firstRow:'Ensemble di musica tradizionale irlandese militante dal 2017', 
        secondRow:'Il tutto nasce come un gruppo di amici appassionati dell\'Isola di Smeraldo, riunitisi sotto il goliardico vessilo “Neurospin”, nome poi abbandonato ad inizio 2023 in favore dell \'attuale monicker.', 
        thirdRow:'Nel corso degli anni abbiamo calcato diversi palchi nelle zone del milanese, comasco e varesotto, principalmente nell \'ambito di eventi ed associazioni di settore.',
        fourthRow: 'Ciascuno di noi ha alle spalle esperienze musicali a dir poco variegate, oltre che tanti anni di studio del genere irish.',
        fifthRow:'Il nostro repertorio alterna le classiche jigs, reel, polkas, tunes che tutti conoscete ed amate (e se non le conoscete tranquilli, siam qui noi) a composizioni originali di stampo squisitamente neotrad.',
        greeting: 'Vi abbiamo incuriositi? Contattateci.Provare per credere! Nùn ghe sèm, e vialter?',
        immagine:Allofus
    }
  }
};

