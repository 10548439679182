import React, { useEffect, useState } from 'react';

// component imports
import Loading from './loading';

// react router dom library features import
import { useNavigate } from 'react-router-dom';


// features import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSadCry } from '@fortawesome/free-solid-svg-icons';

const Errorpage = () => {
// component hooks 
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const notFound = 'Page not Found';

  useEffect(() => {
    setTimeout(()=>{
      setIsloading(false)
    }, 1500)
  });

  useEffect(()=> {
    setTimeout(()=> {
      navigate('/')
    }, 3000)
  });

  return (
    <>
      {
        isLoading
            ?
        <Loading/>
            :
        <div style={{fontFamily:'Celticmd', color:'green', textAlign:'center'}} className='p-3'>
          <FontAwesomeIcon icon={faFaceSadCry} />
          <br></br>
          <b>{notFound}..</b>
        </div>
      }
     
    </>
  )
   
    
};

export default Errorpage;
