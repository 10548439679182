import React from 'react';
import '../../style/button.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

// data imports
import { buttonLinks } from '../../data/links';

// deconstruction
const {target, rel, instaLink, faceLink, mailTo} = buttonLinks;

export default function Button() {
    return (
    <>
      <div className='buttons'>
        <a onClick={instaLink} className='fontInstagram' href={instaLink} target={target} rel={rel}>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a onClick={faceLink} className='fontFacebook' href={faceLink} target={target} rel={rel}>
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a onClick={mailTo} className='fontEmailBox' href={mailTo}>
          <FontAwesomeIcon icon={faMailBulk} />
        </a>
      </div>
    </>
    )
};
