import React from 'react'

// stylesheet import
import '../../style/loading.css'

function Loading() {
  return (
    <div id='spinnerSpace'  className='text-center spinner space'>
        <div className='spinner-border text-success'/>
        <p className='text-success mt-2 animate__animated animate__fadeIn'>Loading...</p>
    </div>
  )
};

export default Loading;
