// features import
import React, { useEffect, useState, useRef, } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// component import
import Modal from './modal';


// style import
import '../../style/card.css';
import 'animate.css/animate.min.css';

function Card({nome, ruolo, immagine, info, instaProfile, faceProfile}) {

  // component hooks
  const [showModal, setShowmodal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  });

  // methods for changing component state
  const handleShowModal = () => {
    setShowmodal(true)
  };

  const handleCloseModal = () => {
    setShowmodal(false)
  };

  const handleClickOutside = (e) => {
    if(modalRef.current && !modalRef.current.contains(e.target)) {
      handleCloseModal()
    }
  };

//  styling declaration while i am showing modal
  const cardStyle = {
    filter: showModal ? 'grayscale(90%)' : 'none',
    transition: 'filter 1s ease',
  };

  return (
    <>
        <div className="col d-flex" style={cardStyle}>
            <div onClick={handleShowModal} className="card">
                <img src={immagine} className="card-img-top" alt="..."/>
                <div className="card-body">
                    <h5 className="card-title">{nome}</h5>
                    <p className="card-text">{ruolo}</p>
                </div>
            </div>
          </div>
            <AnimatePresence>
            {showModal && (
                <motion.div
                  className='modal-container' 
                  style={{position:'absolute'}} 
                  ref={modalRef} 
                  onClick={handleCloseModal}
                  animate={{opacity:1}}
                  exit={{opacity:0}}
                  >
                 <Modal
                     nome={nome}
                     info={info}
                     instaProfile={instaProfile}
                     faceProfile={faceProfile}
                 />
                </motion.div>
                )}
            </AnimatePresence>
    </>
  )
};

export default Card;
