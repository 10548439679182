import React from 'react';

// style import
import '../../style/group.css';

function Group({logo}) {
  const {title, firstRow, secondRow, thirdRow, fourthRow, fifthRow, greeting, immagine} = logo.groupInfo;
  return (
    <>
    <div className='group-info bg bg-dark' style={{ display: 'flex', alignItems: 'center' }}>
       <div className='item'>
          <h2 className='title'>{title}</h2>
          <h6 className='first-row'>{firstRow}</h6>
          <h6 className='second-row'>{secondRow}</h6>
          <h6 className='third-row'>{thirdRow}</h6>
          <h6 className='fourth-row'>{fourthRow}</h6>
          <h6 className='fifth-row'>{fifthRow}</h6>
          <h6 className='greeting'>{greeting}</h6>
        </div>
        <div className='card imgGroup'>
          <img className='img border border-success'  src={immagine} alt="img"></img>
        </div>
    </div>
  </>
  )
}

export default Group;