// all the routes of the web application

// component with route import
import App from "../App";
import { Addevent } from "../screen/components/addEvent";
import { AddPastEvent } from "../screen/components/addPastevent";
import Login from "../screen/components/auth/login";

// error page component import
import Errorpage from "../screen/components/errorPage";

export const routes = [
    {
        path:'/',
        element: <App/>
    },
    {
        path: '/add_event',
        element: <Addevent/>
    },
    {
        path: '/add_past_event',
        element: <AddPastEvent/>
    },
    {
        path:'login',
        element: <Login/>
    },
    // auth component routes
    {
        path: '*',
        element: <Errorpage/>
    }
]
