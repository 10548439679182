import { useState } from 'react';

// style import
import '../../style/event.css';
import 'animate.css/animate.min.css';


function Event({titolo, data, oraInizio, oraFine, luogo, descrizioneLuogo}) {
  // component hook
  const [isHovered, setIshovered] = useState(true);
 
  // methods for changing component state
  const handleMouseEnter = () => {
    setIshovered(false)
  };

  const handleMouseLeave = () => {
    setIshovered(true)
  };

  const linkStyle = {
    color: isHovered ? 'white': 'green',
    transition: 'color 0.5s ease'
  };

  return (
    <div>
        <>
          <table className="table table-dark container border border-success programmed animate__animated animate__slideInLeft ">
            <tbody>
              <tr>
                <td colSpan='2'>{titolo}</td>
              </tr>
                <tr>
                    <th scope="row">Data</th>
                    <td colSpan="2">{data}</td>
                </tr>
                <tr>
                    <th scope="row">Dalle</th>
                    <td colSpan="2">{oraInizio}</td>
                </tr>
                <tr>
                    <th scope="row">Alle</th>
                    <td colSpan="2">{oraFine}</td>
                </tr>
                <tr>
                    <th scope="row">Luogo</th>
                    <td 
                      onMouseEnter={handleMouseEnter} 
                      onMouseLeave={handleMouseLeave} 
                      colSpan="2"
                    >
                        <a 
                          style={linkStyle} 
                          className='Eventlink' 
                          href={luogo} 
                          target={'target'} 
                          rel={'rel'}
                          >
                            {descrizioneLuogo}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
      </>  
    </div>
  )
}

export default Event;