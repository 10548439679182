export const firebaseConfig = {
    apiKey: "AIzaSyClDn7-_MCOrB3yozB_sr7qC0bNR8lwyg4",
    authDomain: "guess-am-events-76cf7.firebaseapp.com",
    databaseURL: "https://guess-am-events-76cf7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "guess-am-events-76cf7",
    storageBucket: "guess-am-events-76cf7.appspot.com",
    messagingSenderId: "509499317351",
    appId: "1:509499317351:web:225d7533071756356108c2",
    measurementId: "G-0K70RWVSSY",
    loginApi: "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key="
};