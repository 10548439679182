// features import
import React, { useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// components import
import Nav from './nav';
import Card from './card';
import Background from './background';
import Footer from './footer';

// data import
import { footer } from '../../data/footer';
import { navbar } from '../../data/navbar';
import { useFetchPresentation, useFetchCards } from '../../data/useFetch';

// stylesheet import
import '../../style/home.css'


const Home = () => {
    const [showCards, setShowcards] = useState(false);
    const presentations = useFetchPresentation();
    const cards = useFetchCards();
  
    const handleToggleCards = () => {
      setShowcards(!showCards)
    };
  
  return (
    <div>
      {presentations.map((presentation, index) => {
        if(showCards) {
          return(
            <Background
              key={index}
              {...presentation}
              filter={'brightness(50%)'}
            />
          )
        }else if(!showCards) {
          return (
            <Background
              key={index}
              {...presentation}
            />
          )
        } else {
          return null
        }
        })}
         <Nav
            handleToggleCards={handleToggleCards}
            navbar={navbar}
          />
          <div className='card-container'>
                <AnimatePresence>
                  {showCards && (
                    cards.map((card, index) => {
                      return (
                        <>
                          <motion.div
                              initial={{opacity:0}}
                              animate={{opacity:1}}
                              exit={{opacity:0}}
                          >
                            <Card
                                key={index}
                                {...card}
                              />
                          </motion.div>
                      </>
                      )
                    })  
                  )}
                </AnimatePresence>
          </div>
          <div className='footer-container' style={{position:'relative'}}>
            <Footer
              {...footer}
            />
          </div>
    </div>
  )
}

export default Home;
